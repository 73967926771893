import { GET_GOALS } from '../actions/types';

    const INITIAL_STATE = {
       goals: [],
     };

     export default (state = INITIAL_STATE, action) => {
       switch (action.type) {
         case GET_GOALS:
           return { ...state, goals: action.payload };
         default:
           return state;
       }
     };
