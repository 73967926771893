import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import cards from './cards';
import { Timeout } from 'react-on-time';

import NewsCards from './newsCards';
import Loader from './loader';

const NewsList = (props) => {
  const {
   templateUpdate, template, parentTemplate, setChoosedWidget,
  } = props;
  const [pageNum, setPageNum] = useState(2);
  const [loading, setLoading] = useState(false);
  const openSetting = (e) => {
    setChoosedWidget(template);
    e.preventDefault();
    e.stopPropagation();
  };
  const newsGrouping = (news) => {
    const groupedNews = [];
    news.forEach((element) => {
      if (Object.keys(groupedNews).includes(dateFormat(element.creationDate))) {
        groupedNews[dateFormat(element.creationDate)].push(element);
      } else {
        groupedNews[dateFormat(element.creationDate)] = [element];
      }
    });
    return groupedNews;
  };
  const dateFormat = (date) => moment(date).format('D MMMM');
  const lazyload = async (e) => {
    // if (!props.isLastPage) setLoading(true);
    if ((e.target.scrollHeight - e.target.clientHeight) - e.target.scrollTop <= 0 && loading) {
      // e.target.scrollTo(0, e.target.scrollHeight);
      // await dispatch(actions.fetchNewsByPage(pageNum, cards.published));
      setPageNum(pageNum + 1);
      setLoading(false);
    }
  };
  moment.locale('ru');

  return (
    <div
      onClick={openSetting}
      className="news-list"
      onScroll={lazyload}
      style={{
          backgroundColor: template.variable.background.backgroundColor,
          boxShadow: '0px 0px 0px #000',
          padding: template.variable.padding,
          borderRadius: template.variable.borderRadius,
          minHeight: template.variable.minHeight,
          maxHeight: template.variable.maxHeight,
          fontSize: `${template.variable.fontSize}px`,
          fontWeight: template.variable.fontWeight,
          display: template.variable.display,
        }}
    >
      {Object.keys(newsGrouping(cards)).map((groupKey, groupIndex) => (
        <Timeout timeout={300 * groupIndex + 1} key={groupIndex}>
          {(timedout) => (
            <div key={groupIndex} className={`news-list_day-group ${timedout ? 'animate-news-items' : ''}`}>
              <div className="news-list_day-group_title">{groupKey}</div>
              <div
                className="news-list_day-group_items"
                style={{
                  display: template.variable.display,
                }}
              >
                {newsGrouping(cards)[groupKey].map((news, index) => (
                  <NewsCards key={`${groupIndex}-${index}`} news={news} variable={template.variable} />
                ))}
              </div>
            </div>
          )}
        </Timeout>
      ))}
      {!!loading && <Loader />}
    </div>
  );
};

export default NewsList;
