import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import axios from 'axios';
import { updateWidget2 } from '../hooks/useWidget';

import Instruments from '../components/pages';
import PreviewWindow from '../components/previewWindow';
import Settings from '../components/setting';

const PreViewPage = () => {
  useEffect(() => {
    axios.get('https://evtelsat.smart-ts.ru/templates?id=5024c7e-46bf-6874-d23b-37804824480').then((res) => {
      setTemplate(res.data.template);
    });
  }, []);
  const [template, setTemplate] = useState();

  return (
    <div className="container">
      <div className="columns">
        <div className="column first">
          <PreviewWindow template={template} />
        </div>
      </div>
    </div>
  );
};

export default PreViewPage;
