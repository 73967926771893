import React from 'react';

const Request = () => {
  const requests = [{
    title: 'Заявка на отпуск',
    status: 'success',
    statusText: 'Согласовано',
  }, {
    title: 'Заявка на отпуск',
    status: 'success',
    statusText: 'Согласовано',
  }, {
    title: 'Заявка на отпуск',
    status: 'success',
    statusText: 'Согласовано',
  }, {
    title: 'Заявка на отпуск для собачки тёти Семии из пригорода нью Гушвиля.',
    status: 'waiting',
    statusText: 'На согласовании',
  }];
  return (
    <div className="requests-widget">
      <div className="requests-wiget_title">
        Заявки
        <i className="fa fa-plus" />
      </div>
      <div className="requests-wiget_content">
        { requests.map((request, index) => (
          <div key={index} className="requests-wiget_content_item">

            <div className="requests-wiget_content_item_name">
              { request.title }
            </div>
            <div className={`requests-wiget_content_item_action requests-wiget_content_item_action__${request.status}`}>
              { request.statusText }
            </div>
          </div>
        )) }
      </div>
    </div>
  );
};

export default Request;
