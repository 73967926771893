/* eslint-disable import/prefer-default-export */
import { GET_GOALS } from './types';
import goalsState from '../../state/goalsState';
import axios from 'axios';

const goals = [
    {
        id: '00000000-0000-0000-0000-000000000000',
        status: 0,
        name: 'Экзамен "Microsoft Dynamics CRM Customization and Configuration',
        achievement: 'Сдать экзамен "Microsoft Dynamics CRM Customization and Configuration',
        startDate: '2021-08-05T09:33:30.2416846',
        endData: '2021-08-09T09:33:30.2416846',
    },
    {
        id: '00000000-0000-0000-0000-000000000002',
        status: 1,
        name: 'Экзамен "Microsoft Dynamics CRM Customization and Configuration',
        achievement: 'Сдать экзамен "Microsoft Dynamics CRM Customization and Configuration',
        startDate: '2021-08-05T09:33:30.2416846',
        endData: '2021-08-09T09:33:30.2416846',
    },
    {
        id: '00000000-0000-0000-0000-000000000003',
        status: 2,
        name: 'Экзамен "Microsoft Dynamics CRM Customization and Configuration',
        achievement: 'Сдать экзамен "Microsoft Dynamics CRM Customization and Configuration',
        startDate: '2021-08-05T09:33:30.2416846',
        endData: '2021-08-09T09:33:30.2416846',
    },
    {
        id: '00000000-0000-0000-0000-000000000004',
        status: 3,
        name: 'Экзамен "Microsoft Dynamics CRM Customization and Configuration',
        achievement: 'Сдать экзамен "Microsoft Dynamics CRM Customization and Configuration',
        startDate: '2021-08-05T09:33:30.2416846',
        endData: '2021-08-09T09:33:30.2416846',
    },
    {
        id: '00000000-0000-0000-0000-000000000005',
        status: 4,
        name: 'Экзамен "Microsoft Dynamics CRM Customization and Configuration',
        achievement: 'Сдать экзамен "Microsoft Dynamics CRM Customization and Configuration',
        startDate: '2021-08-05T09:33:30.2416846',
        endData: '2021-08-09T09:33:30.2416846',
    },
];

export const getGoals = () => (dispatch) => {
    setTimeout(() => {
      dispatch({
          type: GET_GOALS,
          payload: goals,
      });
    }, 1000);
  };
