import {
  LOG_IN, LOG_OUT, REFRESH,
} from '../actions/types';

const INITIAL_STATE = {
  isAuth: !!localStorage.getItem('token'),
  token: '',
  userData: {},
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case LOG_IN:
      return {
        ...state,
        isAuth: action.payload.isAuth,
        token: action.payload.token,
        userData: action.payload.user,
      };
    case REFRESH:
      return {
        ...state,
        isAuth: action.payload.isAuth,
        token: action.payload.token,
        };
    case LOG_OUT:
      return INITIAL_STATE;
    default:
      return state;
  }
};
