import React, { Suspense } from 'react';
import PropTypes from 'prop-types';

const CentrModal = ({
 title, component, width, height, handlerCenterModalStatus, status, elementId,
}) => {
  const loadComponent = () => React.lazy(() => import(`./${component}.js`));
  const Component = loadComponent(component);
  const handlerModal = (event) => {
    if (event.target.classList.contains('modal')) {
      handlerCenterModalStatus(false);
    }
  };
    return (
      <div
        className={status ? 'modal centr-modal modal_active' : 'modal centr-modal modal_hide'}
        onClick={(event) => handlerModal(event)}
      >
        <div
          className={status ? 'modal_content-centr-modal active-centr-modal-content' : 'modal_content-centr-modal'}
          style={{ width, height }}
        >
          {title && <div className="modal_content-centr-modal_title">{title}</div>}
          <div className="modal_content-centr-modal_container">
            <Suspense fallback={<div>Loading...</div>}>
              <Component
                handlerCenterModalStatus={handlerCenterModalStatus}
                elementId={elementId}
              />
            </Suspense>
          </div>
        </div>
      </div>
    );
};

CentrModal.propTypes = {
  component: PropTypes.string.isRequired,
  handlerCenterModalStatus: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
};

export default CentrModal;
