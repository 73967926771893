import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { IoIosArrowDown } from 'react-icons/io';

const Search = (props) => {
  const [text, setText] = useState('');
  const handleChange = (e) => {
    setText(e.target.value);
  };
  return (
    <div className="companent-search">
      <input placeholder={props.placeholder} type="text" value={text} onChange={handleChange} />
    </div>
  );
};

export default Search;
