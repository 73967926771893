import * as actions from '../store/actions/loginAction';
import { connect, useSelector } from 'react-redux';
import React, { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

const LoginPage = (props) => {
  const { dispatch } = props;
  const { history } = props;
  const isAuth = useSelector((state) => state.user.isAuth);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const emailHandle = (e) => setEmail(e.target.value);
  const passwordHandle = (e) => setPassword(e.target.value);
  const login = async () => {
    setErrorEmail(false);
    setErrorPassword(false);
    if (email == '') setErrorEmail(true);
    if (password == '') setErrorPassword(true);
    if (email != '' && password != '') {
      console.log(email, password);
      dispatch(actions.login(email, password));
    }
  };
  const handleKeyPress = (target) => {
    if (target.charCode == 13) {
      login();
    }
  };
  return (
    <div className="login-wrapper">
      <div className="main">
        <div className="container a-container" id="a-container">
          <form className="form" id="a-form" method="true" action="true">
            <h2 className="form_title title">Войти</h2>
            <input className={errorEmail ? 'error form__input' : 'form__input'} onKeyPress={handleKeyPress} value={email} onChange={emailHandle} type="text" placeholder="E-mail или Логин" />
            <input className={errorPassword ? 'error form__input' : 'form__input'} onKeyPress={handleKeyPress} value={password} onChange={passwordHandle} type="password" placeholder="Пароль" />
            <a href="/#" className="form__link forgotPassword">Забыли пароль?</a>
            <button type="button" onClick={() => { login(); }} className="form__button button submit login">Войти</button>
          </form>
        </div>
        <div className="container b-container" id="b-container">
          <form className="form" id="b-form" method="true" action="true">
            <h2 className="form_title title">Регистрация</h2>
            <input className="form__input" name="login" type="text" placeholder="Логин" />
            <input className="form__input" name="email" type="email" placeholder="E-mail" />
            <input className="form__input" name="b_date" type="text" placeholder="Дата рождения" />
            <input className="form__input" name="password" type="password" placeholder="Пароль" />
            <button type="button" className="form__button button submit signin">Регистрация</button>
          </form>
        </div>
        <div className="switch" id="switch-cnt">
          <div className="switch__circle switch__circle--t" />
          <div className="switch__circle " />
          <div className="switch__container is-hidden" id="switch-c2">
            <button type="button" className="switch__button button switch-btn">Вход</button>
          </div>
          <div className="switch__container" id="switch-c1">
            <button type="button" className="switch__button button switch-btn">Регистрация</button>
          </div>
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  user: state.user.userData,
  isAuth: state.user.isAuth,
  token: state.user.token,
});

export default withRouter(connect(mapStateToProps)(LoginPage));
