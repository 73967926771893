import React, { useState, useEffect } from 'react';
import uuid from 'react-uuid';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import * as actions from '../store/actions/templateAction';
import $api, { API_URL } from '../http';
import { updateWidget2 } from '../hooks/useWidget';

import Instruments from '../components/pages';
import EditorWindow from '../components/editorWindow';
import Settings from '../components/setting';

const Editor = (props) => {
  const { dispatch } = props;
  const templateHandler = (template) => {
    localStorage.setItem('scrollTop', document.querySelector('.editor-window_container').scrollTop);
    setTemplate(template);
    setEditorKey(editorKey + 1);
  };
  const saveTemplate = async () => {
    dispatch(actions.saveTemplate(templateid, {
      name: mainTemplate.name,
      template,
      id: mainTemplate.id,
      metaClass: null,
      metaViewFields: null,
    }));
  };
  const updateWidget = (widget) => templateHandler(updateWidget2(template, widget));
  useEffect(async () => {
    setTimeout(() => {
      document.querySelector('.editor-window_container').scrollTop = localStorage.getItem('scrollTop') || 0;
    }, 0);
  });
  useEffect(async () => {
    // dispatch(actions.getTemplates());
    // await $api.get(`templates/${templateid}`).then((res) => {
    //   console.log(props);
    //   setTemplate(res.data.template);
    //   setMainTemplate(res.data);
    // });
  }, []);
  const [editorKey, setEditorKey] = useState(0);
  const templateid = Object.fromEntries(new URLSearchParams(props.history.location.search).entries()).id;
  const templateEl = useSelector((state) => {
    const el = state.page.templates.filter((el) => el.id == templateid)[0];
    el.mainTemplate = 'columnWidget';
    console.log(el.template);
    el.template = typeof (el.template) == 'string' ? JSON.parse(el.template) : el.template;
    return el;
  });
  const [template, setTemplate] = useState(templateEl.template.template);
  const [mainTemplate, setMainTemplate] = useState(templateEl.template);
  const [choosedWidget, setChoosedWidget] = useState(null);
  const setChoosedWidgetHandler = (widget, e) => {
    localStorage.setItem('scrollTop', document.querySelector('.editor-window_container').scrollTop);
    setChoosedWidget(widget);
  };
  return (
    <div className="container" key={editorKey}>
      <div className="columns">
        <div className="column first">
          <EditorWindow templateUpdate={templateHandler} template={template} parentTemplate={template} setChoosedWidget={setChoosedWidgetHandler} />
        </div>
        <div className="column">
          <Settings widget={choosedWidget} saveTamplate={saveTemplate} updateWidget={updateWidget} />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    template: state.page.template,
  };
};

export default withRouter(connect(mapStateToProps)(Editor));
