import React from 'react';
import { useSelector } from 'react-redux';

import Header from './header';

const MainLayout = (props) => {
    return (
      <>
        <Header />
        { props.children }
      </>
    );
};

export default MainLayout;
