import React, { useState } from 'react';
import moment from 'moment';
import goalsState from '../state/goalsState';
import Selector from './selector';
import Search from './search';
import {
  FiChevronRight,
  FiPlus,
  FiList,
  FiFolder,
} from 'react-icons/fi';
import {
 FaFolderOpen,
 FaFolder,
 FaCaretRight,
 FaCaretDown,
} from 'react-icons/fa';

const DirectoryGoals = ({ goals }) => {
    console.log(goals);
    const sections = [{
      title: 'Front-end',
      childrens: [{
        title: 'Front-end Триколор',
      }, {
        title: 'C++',
      }],
    }, {
      title: 'Back-end',
      childrens: [{
        title: 'Back-end Триколор',
      }, {
        title: 'C++',
      }],
    }, {
      title: 'SQL',
      childrens: [{
        title: 'SQL Триколор',
      }, {
        title: 'SQL C++',
      }],
    }];
    const [openedSection, setOpenedSection] = useState(null);
    return (
      <div className="directory-goals">
        <div className="directory-goals_sections">
          <div className="directory-goals_sections_create">
            <FiPlus />
            Создать раздел
          </div>
          <div className="directory-goals_sections_all-section">
            <FiList />
            Все цели
          </div>
          {sections?.map((section, sectionIndex) => (
            <div className="directory-goals_sections_item" onClick={() => setOpenedSection(openedSection == sectionIndex ? null : sectionIndex)}>
              <div className="directory-goals_sections_item_title">
                { openedSection == sectionIndex
                  ? (<FaCaretDown className="directory-goals_sections_item_title_caret" />)
                  : (<FaCaretRight className="directory-goals_sections_item_title_caret" />) }
                { openedSection == sectionIndex ? (<FaFolderOpen />) : (<FaFolder />) }
                {section.title}
              </div>
              { openedSection == sectionIndex && section.childrens?.map((child) => (
                <div className="directory-goals_sections_item_child">
                  <FiFolder />
                  {child.title}
                </div>
              ))}
            </div>
          ))}
        </div>
        <div className="directory-goals_section-content">
          <div className="directory-goals_section-content_navigation">
            <div className="directory-goals_section-content_navigation_item">Back-end</div>
            <FiChevronRight className="directory-goals_section-content_navigation_item_item-arrow" />
            <div className="directory-goals_section-content_navigation_item">Back-end Триколор</div>
          </div>
          <div className="directory-goals_section-content_filters">
            <Search placeholder="Поиск по названию" />
            <Search placeholder="Поиск по ФИО пользователя" />
            <Selector />
            <Selector />
          </div>
          <div className="directory-goals_section-content_grid" />
        </div>
      </div>
    );
};
export default DirectoryGoals;
