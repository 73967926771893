import React from 'react';

const NewsCards = ({ news, variable }) => {
  return (
    <div>
      <div className="news-card" role="list" style={{ width: `${variable.cardWidth}px`, height: `${variable.cardHeight}px` }}>
        <div className="news-card_items_item_image" style={{ backgroundImage: `url('${news.backgroundImageUrl}')` }} />
        <div className="news-card_items_item_info">
          <div className="news-card_items_item_info_city">{news.authorDepartmentName}</div>
          <div className="news-card_items_item_info_title">{news.title}</div>
          <div className="news-card_items_item_info_description">{news.description.split(' ').length >= 8 ? `${news.description.split(' ').slice(0, 8).join(' ') }...` : news.description}</div>
        </div>
      </div>
    </div>
  );
};

export default NewsCards;
