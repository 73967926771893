import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import * as actions from '../store/actions/newsAction';

import NewsList from '../components/newsList';
import MainNews from '../components/mainNews';
import Loader from '../components/loader';

import NewsNavBar from '../components/newsNavBar';
import NewsNavigation from '../components/newsNavigation';
import NewsArchive from '../components/newsArchive';
import EditingNews from '../components/editingNews';
import DraftsNews from '../components/draftsNews';

const NewsPage = (props) => {
  const { history } = props;
  const { dispatch } = props;
  useEffect(() => {
    dispatch(actions.fetchNewsByPage(1));
  }, []);
  return (
    <div className="news-wrapper">
      <NewsNavBar />
      {Object.keys(props.news).length !== 0 && (
      <div className="container container-news">
        <NewsNavigation />
        <div className="row">
          <Router>
            <Switch>
              <Route exact path="/news/newsList">
                {props.news.published.length !== 0 ? <NewsList news={props.news.published} /> : <Loader />}
                {props.news.published.length !== 0 ? <MainNews news={props.news.published[0]} /> : <Loader />}
              </Route>
              <Route exact path="/news/archive">
                {props.news.archived.length !== 0 ? <NewsArchive news={props.news.archived} /> : <Loader />}
              </Route>
              <Route exact path="/news/editing">
                {props.news.length !== 0 ? <EditingNews news={props.news.editing} /> : <Loader />}
              </Route>
              <Route exact path="/news/draft">
                {props.news.length !== 0 ? <DraftsNews news={props.news.draft} /> : <Loader />}
              </Route>
            </Switch>
          </Router>
        </div>
      </div>
)}
    </div>
  );
};
const mapStateToProps = (state) => ({
  news: state.news.arr,
  latestNews: state.news.latestNews,
});

export default withRouter(connect(mapStateToProps)(NewsPage));
