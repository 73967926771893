import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { HiPencil } from 'react-icons/hi';
import { MdArchive } from 'react-icons/md';
import SideModal from './sideModal';
import CentrModal from './centrModal';
import { Timeout } from 'react-on-time';

const Grid = (props) => {
    const [elementIdSideModal, setElementIdSideModal] = useState(null);
    const [elementIdCentrModal, setElementIdCentrModal] = useState(null);
    const [sideModal, setSideModal] = useState(false);
    const [centrModal, setCentrModal] = useState(false);
    const handlerSideModalStatus = (status, id) => {
      setSideModal(status);
      setElementIdSideModal(id);
    };
    const handlerCenterModalStatus = (status, id) => {
      setCentrModal(status);
      setElementIdCentrModal(id);
    };
    return (
      <div className="news-editing">
        {!!props.news.newsCount
        && (
        <div className="news-editing_rows">
          <div className="news-editing_rows_headings">
            <div className="news-editing_rows_heading">пользователь</div>
            <div className="news-editing_rows_heading">заголовок</div>
            <div className="news-editing_rows_heading">подразделение</div>
            <div className="news-editing_rows_heading">дата</div>
          </div>
          <div className="news-editing_rows_news-items">
            {props.news.newsList.map((item, index) => {
              return (
                <Timeout timeout={100 * index + 1} key={item.id}>
                  {(timedout) => (
                    <div className={`news-editing_rows_news-item ${timedout ? 'animate-news-editing-items' : ''}`} key={item.id}>
                      <div className="news-editing_rows_news-item-user">
                        <div className="news-editing_rows_news-item-user-image" />
                        {item.authorName.split(/\s+/).map((w, i) => (i ? `${w.substring(0, 1).toUpperCase() }.` : w)).join(' ')}
                      </div>
                      <div className="news-editing_rows_news-item-title">
                        {item.title.split(' ').length >= 5 ? `${item.title.split(' ').slice(0, 5).join(' ') }...` : item.title}
                        {item.title.split(' ').length >= 5
                        && <div className="news-editing_rows_news-item-full_title">{item.title}</div>}
                      </div>
                      <div className="news-editing_rows_news-item-subdivision">{item.authorDepartmentName}</div>
                      <div className="news-editing_rows_news-item-date">{moment(item.creationDate).format('DD.MM.YYYY')}</div>
                      <div className="news-editing_rows_news-item-settings">
                        <HiPencil onClick={() => handlerSideModalStatus(true, item.id)} />
                        <MdArchive onClick={() => handlerCenterModalStatus(true, item.id)} />
                      </div>
                    </div>
                  )}
                </Timeout>
              );
            })}
          </div>
          <CentrModal title="Перенести в архив?" status={centrModal} elementId={elementIdCentrModal} handlerCenterModalStatus={handlerCenterModalStatus} component="transferNewsToArchive" />
          <SideModal title="Редактирование новости" width="70%" elementId={elementIdSideModal} status={sideModal} handlerSideModalStatus={handlerSideModalStatus} component="editNews" />
        </div>
        )}
        {!props.news.newsCount
          && <div className="news-editing-empty-message">список пуст</div>}
      </div>
    );
};

export default Grid;
