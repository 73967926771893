import React from 'react';

const Objectives = () => (
  <div className="objectives-widget">
    <div className="objectives-wiget__title">
      Цели
    </div>
    <div className="objectives-wiget__content">
      <div className="objectives-wiget__content__icon">
        <i className="fa fa-question" />
      </div>
      <div className="objectives-wiget__content__text">
        Экзамен "Microsoft Dynamics CRM Customization and Configuration"
        <div className="indicator">
          <div className="carriage" />
        </div>
      </div>
    </div>
  </div>
);

export default Objectives;
