import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import * as actions from '../store/actions/newsAction';

import Loader from '../components/loader';
import Objectives from '../components/objectives';
import Courses from '../components/courses';
import UserCard from '../components/userCard';
import UserInfo from '../components/userInfo';
import Request from '../components/request';

const ProfilePage = (props) => {
  const { history } = props;
  const { dispatch } = props;
  useEffect(() => {
    // dispatch(actions.fetchNewsByPage(1));
  }, []);
  return (
    <div className="container profile-page">
      <div className="columns">
        <div className="column first">
          <UserCard />
          <UserInfo />
        </div>
        <div className="column">
          <Objectives />
          <Courses />
        </div>
        <div className="column">
          <Request />
        </div>
      </div>
    </div>
  );
};
const mapStateToProps = (state) => ({
  news: state.news.arr,
  latestNews: state.news.latestNews,
});

export default withRouter(connect(mapStateToProps)(ProfilePage));
