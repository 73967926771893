const goalsState = {
    appointed: {
        text: 'Назначена',
        status: 0,
        background: '#0d58a626',
        color: '#0D58A6',
    },
    completed: {
        text: 'Выполнена',
        status: 1,
        background: '#02870b26',
        color: '#02870B',
    },
    notExecuted: {
        text: 'Не Выполнена',
        status: 2,
        background: '#cc000026',
        color: '#CC0000',
    },
    canceled: {
        text: 'Отменена',
        status: 3,
        background: '#E8E8E8',
        color: '#646464',
    },
    onCheck: {
        text: 'На проверке',
        status: 4,
        background: '#ff990026',
        color: '#FF9900',
    },
    draft: {
        text: 'Черновик',
        status: 5,
        background: '#E8E8E8',
        color: '#646464',
    },
    onApproval: {
        text: 'На согласовании',
        status: 6,
        background: '#ff990026',
        color: '#FF9900',
    },
};

export default goalsState;
