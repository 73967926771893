import React from 'react';

const Objectives = () => (
  <div className="courses-widget">
    <div className="courses-wiget__title">
      Курсы
    </div>
    <div className="courses-wiget__content">
      <div className="courses-wiget__content__icon">
        <i className="fa fa-question" />
      </div>
      <div className="courses-wiget__content__text">
        Подготовка к  Экзамену "Microsoft Dynamics CRM Customization and Configuration"
        <div className="indicator">
          <div className="carriage" />
        </div>
      </div>
    </div>
  </div>
);

export default Objectives;
