import { combineReducers, createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';

import newsReducer from './newsReducer';
import popupReducer from './popupReducer';
import loginReducer from './loginReducer';
import filesReducer from './filesReducer';
import templateReducer from './templateReducer';
import attestationReducer from './attestationReducer';

const rootReducer = combineReducers({
  page: templateReducer,
  news: newsReducer,
  user: loginReducer,
  popup: popupReducer,
  files: filesReducer,
  attestation: attestationReducer,
});

const store = createStore(rootReducer, {}, applyMiddleware(thunk));

export default store;
