import React, { useState } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { HiPencil } from 'react-icons/hi';
import { AiOutlineDelete } from 'react-icons/ai';
import { MdUnarchive } from 'react-icons/md';
import SideModal from './sideModal';
import CentrModal from './centrModal';
import { Timeout } from 'react-on-time';

const NewsArchive = (props) => {
    const [elementIdCentrModal, setElementIdCentrModal] = useState(null);
    const [elementIdSideModal, setElementIdSideModal] = useState(null);
    const [sideModal, setSideModal] = useState(false);
    const [centrModal, setCentrModal] = useState(false);
    const [centrModalType, setCentrModalType] = useState('delete');
    const handlerSideModalStatus = (status, id) => {
      setSideModal(status);
      setElementIdSideModal(id);
    };
    const handlerCenterModalStatus = (status, id, type) => {
      setCentrModalType(type);
      setCentrModal(status);
      setElementIdCentrModal(id);
    };
    return (
      <div className="news-archive">
        {!!props.news.newsCount
        && (
        <div className="news-archive_rows">
          <div className="news-archive_rows_headings">
            <div className="news-archive_rows_heading">пользователь</div>
            <div className="news-archive_rows_heading">заголовок</div>
            <div className="news-archive_rows_heading">подразделение</div>
            <div className="news-archive_rows_heading">дата</div>
          </div>
          <div className="news-archive_rows_news-items">
            {props.news.newsList.map((item, index) => {
              return (
                <Timeout timeout={100 * index + 1} key={item.id}>
                  {(timedout) => (
                    <div className={`news-archive_rows_news-item ${timedout ? 'animate-news-archive-items' : ''}`} key={item.id}>
                      <div className="news-archive_rows_news-item-user">
                        <div className="news-archive_rows_news-item-user-image" />
                        {item.authorName.split(/\s+/).map((w, i) => (i ? `${w.substring(0, 1).toUpperCase() }.` : w)).join(' ')}
                      </div>
                      <div
                        className="news-archive_rows_news-item-title"
                      >
                        {item.title.split(' ').length >= 5 ? `${item.title.split(' ').slice(0, 5).join(' ') }...` : item.title}
                        {item.title.split(' ').length >= 5
                        && <div className="news-archive_rows_news-item-full_title">{item.title}</div>}
                      </div>
                      <div className="news-archive_rows_news-item-subdivision">{item.authorDepartmentName}</div>
                      <div className="news-archive_rows_news-item-date">{moment(item.creationDate).format('DD.MM.YYYY')}</div>
                      <div className="news-archive_rows_news-item-settings">
                        <HiPencil onClick={() => handlerSideModalStatus(true, item.id)} />
                        <MdUnarchive onClick={() => handlerCenterModalStatus(true, item.id, 'update')} />
                        <AiOutlineDelete onClick={() => handlerCenterModalStatus(true, item.id, 'delete')} />
                      </div>
                    </div>
                  )}
                </Timeout>
              );
            })}
          </div>
          {centrModalType === 'delete'
          && <CentrModal title="Удалить запись?" elementId={elementIdCentrModal} status={centrModal} handlerCenterModalStatus={handlerCenterModalStatus} component="deleteNews" />}
          {centrModalType === 'update'
          && <CentrModal title="Опубликовать новость?" elementId={elementIdCentrModal} status={centrModal} handlerCenterModalStatus={handlerCenterModalStatus} component="transferNewsToPublish" />}
          <SideModal title="Редактирование новости" elementId={elementIdSideModal} width="70%" status={sideModal} handlerSideModalStatus={handlerSideModalStatus} component="editNews" />
        </div>
        )}
        {!props.news.newsCount
          && <div className="news-archive-empty-message">список пуст</div>}
      </div>
    );
};

export default NewsArchive;
