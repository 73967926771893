import React from 'react';
import moment from 'moment';
import { IMAGE_URL } from '../http';
import { connect, useSelector } from 'react-redux';

const UserInfo = () => {
  const user = useSelector((state) => state.user.userData);
  const userData = [
    {
      title: 'Дата рождения',
      value: `${moment(user.birthDate).format('DD.MM.YYYY')}
      (${moment(new Date()).diff(moment(new Date(user.birthDate)), 'years').toString()}) года`,
    }, {
      title: 'Подразделение',
      value: user.departmentPositions[0].departmentName,
    }, {
      title: 'Телефон',
      value: user.phone,
    }, {
      title: 'Skype',
      value: user.skype,
    }, {
      title: 'E-MAIL',
      value: user.email,
    }, {
      title: 'пароль',
      value: '⦁⦁⦁⦁⦁⦁⦁⦁',
    },
  ];
  console.log(user);
  return (
    <div className="user-info-widget">
      <div className="user-info-wiget_title">
        Мои данные
      </div>
      <div className="user-info-wiget_content">
        {userData?.map((item) => (item.value ? (
          <div className="user-info-wiget_content_item">
            <div className="user-info-wiget_content_item_title">{item.title}</div>
            <div className="user-info-wiget_content_item_value">{item.value}</div>
          </div>
        ) : ''))}
      </div>
    </div>
);
};

export default UserInfo;
