import { GET_TEMPLATE, UPDATE_TEMPLATE, GET_TEMPLATES } from '../actions/types';

const INITIAL_STATE = {
  template: {},
  templates: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case GET_TEMPLATE:
      return { ...state, template: action.payload };
    case GET_TEMPLATES:
      return { ...state, templates: action.payload };
    case UPDATE_TEMPLATE:
      return { ...state };
    default:
      return state;
  }
};
