import React, { useState, Suspense } from 'react';
import { connect } from 'react-redux';
import pageImage, { withRouter } from 'react-router';
import { BiPlus } from 'react-icons/bi';
import SideModal from './sideModal';

const Setting = ({ widget, saveTamplate, updateWidget }) => {
  const [selectedPage, setSelectedPage] = useState(false);
  const [sideEditModal, setSideEditModal] = useState(false);
  const handlerSideEditModalStatus = (status) => {
    setSideEditModal(status);
  };
  const sideEditModalHanler = (page) => {
    setSelectedPage(page);
    handlerSideEditModalStatus(true);
  };
  const loadComponent = (component) => React.lazy(() => import(`./${component}.js`));
  const ChildrenDrower = widget && loadComponent(widget.settigComponent);

  return (
    <div className="setting">
      <div className="setting_header">
        <div className="setting_header_title">Настрокйи</div>
        <div className="setting_header_title_add-page" onClick={() => saveTamplate(true)}>
          <BiPlus />
          Сохранить шаблон
        </div>
      </div>
      <div className="setting_container">
        {widget && (
          <Suspense fallback={<div>Loading...</div>}>
            <ChildrenDrower choosedWidget={widget} widgetHandler={updateWidget} />
          </Suspense>
        )}
      </div>
      <SideModal title="Редактирование страницы" elementId={selectedPage} status={sideEditModal} handlerSideModalStatus={handlerSideEditModalStatus} width="50%" component="editPage" />
    </div>
  );
};

export default Setting;
