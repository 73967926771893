import React from 'react';
import { useLocation } from 'react-router-dom';

import Selector from './selector';
import Search from './search';

const NewsNavigation = (props) => {
  const location = useLocation();
  return (
    <div className="news-navigation">
      {location.pathname !== '/news/draft' && <Selector />}
      <Search placeholder="Поиск по названию" />
    </div>
  );
};

export default NewsNavigation;
