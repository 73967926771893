// NEWS
export const ADD_NEWS = 'add_news';
export const FETCH_NEWS = 'fetch_news';
export const FETCH_NEWS_PAGE = 'fetch_news_page';
export const FETCH_MAIN_NEWS = 'FETCH_NEWS_MAIN_NEWS';
export const SAVE_NEWS = 'save_news';
export const DELETE_NEWS = 'delete_news';
export const UPDATE_STATE_NEWS = 'update_state_news';
export const UPDATE_NEWS = 'update_news';
//---------------------------------------

// Authorization
export const LOG_IN = 'login';
export const LOG_OUT = 'logout';
export const REFRESH = 'refresh';
//---------------------------------------

// POPUP
export const ADD_POPUP = 'add_popup';
export const DELETE_POPUPS = 'delete_popups';
export const DELETE_POPUP = 'delete_popup';
//---------------------------------------

// FILES
export const UPLOAD_IMAGE = 'upload_image';
//---------------------------------------

// GOALS
export const GET_GOALS = 'get_goals';
//---------------------------------------

// TEMPLATE
export const GET_TEMPLATE = 'get_template';
export const GET_TEMPLATES = 'get_templates';
export const UPDATE_TEMPLATE = 'update_template';
//---------------------------------------

// TOOLS
export const TEXT_BLOCK = 'text_block';
export const LINK_BLOCK = 'link_block';
export const IMAGE_BLOCK = 'image_block';
export const LINE_BLOCK = 'line_block';
export const CONTROLS_BLOCK = 'constols_block';
//---------------------------------------
