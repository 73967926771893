import * as axios from 'axios';

export const API_URL = 'https://evtelsat.smart-ts.ru/';
export const IMAGE_URL = 'https://evtelsat.smart-ts.ru/api/service/graph/';

const $api = axios.create({
  withCredentials: true,
  baseURL: API_URL,
});

$api.interceptors.request.use((config) => {
  config.headers.Authorization = `Bearer ${localStorage.getItem('token')}`;
  return config;
});

export default $api;
