import React, { useState } from 'react';
import { HiPencil } from 'react-icons/hi';
import { MdUnarchive } from 'react-icons/md';
import SideModal from './sideModal';
import moment from 'moment';
import { Timeout } from 'react-on-time';

// import DraftsCard from './draftsCards';

const DraftsNews = (props) => {
  const [elementIdSideModal, setElementIdSideModal] = useState(null);
  const [elementIdCentrModal, setElementIdCentrModal] = useState(null);
  const [sideModal, setSideModal] = useState(false);
  const handlerSideModalStatus = (status, id) => {
    setSideModal(status);
    setElementIdSideModal(id);
    console.log(id);
  };
  return (
    <div className="draft">
      {!!props.news.newsCount && (
      <div className="draft-list">
        {props.news.newsList.map((draft, index) => (
          <Timeout timeout={100 * index + 1} key={draft.id}>
            {(timedout) => (
              <div className={`draft-card ${timedout ? 'animate-news-draft-items' : ''}`}>
                <div className="draft-card_image" style={{ backgroundImage: `url('https://localhost:5001/api/service/graph/${draft.backgroundImageUrl}')` }} />
                <div className="draft-card_info">
                  <div className="draft-card_info_title">{draft.title}</div>
                  <div className="draft-card_info_description">{draft.description}</div>
                  <div className="draft-card_info_group">
                    <div className="draft-card_info_group_date">{moment(draft.creationDate).format('DD.MM.YYYY')}</div>
                    <div className="draft-card_info_group_control">
                      <div className="draft-card_info_group_control_edit">
                        <HiPencil onClick={() => handlerSideModalStatus(true, draft.id)} />
                      </div>
                      <div className="draft-card_info_group_control_restor">
                        <MdUnarchive />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
             )}
          </Timeout>
      ))}
        <SideModal title="Редактирование новости" status={sideModal} elementId={elementIdSideModal} handlerSideModalStatus={handlerSideModalStatus} width="70%" component="editNews" />
      </div>
      )}
      {!props.news.newsCount
        && <div className="draft-empty-message">список пуст</div>}
    </div>
  );
};

export default DraftsNews;
