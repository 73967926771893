import $api, { API_URL } from '../../http';
import { GET_TEMPLATE, GET_TEMPLATES, UPDATE_TEMPLATE } from './types';

export const getTemplates = () => async (dispatch) => {
  const template = await $api.get('templates/list');
  dispatch({
    type: GET_TEMPLATES,
    payload: template.data,
  });
  console.log(template);
};

export const getTemplate = (tempateId) => async (dispatch) => {
  const template = await $api.get(`Templates/${tempateId}`);
  console.log(template);
  dispatch({
    type: GET_TEMPLATE,
    payload: template.data.template,
  });
};

export const saveTemplate = (id, template) => async (dispatch) => {
  console.log(template);
  $api.post('https://evtelsat.smart-ts.ru/Templates/saveJson', template).then((res) => {
    console.log(res);
  });
  // const rawResponse = await fetch('https://evtelsat.smart-ts.ru/Templates/saveJson', {
  //     method: 'POST',
  //     mode: 'cors',
  //     headers: {
  //       Accept: 'application/json',
  //       'Content-Type': 'application/json',
  //       'Access-Control-Allow-Origin': '*',
  //     },
  //     body: JSON.stringify(template),
  //   });
};
