// Значения popup для иконки и цвета алерта

const popupState = {
    warning: 'warning',
    success: 'success',
    notification: 'notification',
    error: 'error',
  };

export default popupState;
