import {
 ADD_POPUP, DELETE_POPUPS,
} from '../actions/types';

  const INITIAL_STATE = {
    popups: [],
  };

  export default (state = INITIAL_STATE, action) => {
    switch (action.type) {
      case ADD_POPUP:
        return { ...state, popups: state.popups.concat([action.payload]) };
      case DELETE_POPUPS:
        return { ...state, popups: action.payload };
      default:
        return state;
    }
  };
