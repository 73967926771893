import React, {
 useState, Suspense, useEffect, useRef,
} from 'react';
import { connect } from 'react-redux';
import pageImage, { withRouter } from 'react-router';
import { BiPlus } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
import SideModal from './sideModal';
import uuid from 'react-uuid';

const EditorWindow = ({
 templateUpdate, template, parentTemplate, setChoosedWidget,
}) => {
  const [viewMode, setViewMode] = useState(false);
  const [sideModal, setSideModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState(false);
  const loadComponent = (component) => React.lazy(() => import(`./${component}.js`));
  console.log(template);
  const ChildrenDrower = template && loadComponent(template.mainTemplate);
  const handlerSideModalStatus = (status) => {
    setSideModal(status);
  };
  const addWidget = (widget) => {
    widget.id = uuid();
    templateUpdate(widget);
  };
  const templateHandler = (template) => {
    templateUpdate(template);
  };
  console.log(template);
  return (
    <div className={(`editor-window ${viewMode ? 'view-mode' : ''}`)}>
      <div className="editor-window_container">
        {template && (
          <Suspense fallback={<div>Loading...</div>}>
            <ChildrenDrower templateUpdate={templateHandler} template={template} parentTemplate={parentTemplate} setChoosedWidget={setChoosedWidget} />
          </Suspense>
        )}
      </div>
      <div className="editor-window_btns">
        <div className="editor-window_btns_add-btn" onClick={handlerSideModalStatus}>
          <BiPlus />
        </div>
        <div className="editor-window_btns_add-btn" onClick={() => setViewMode(!viewMode)}>
          <AiOutlineEye />
        </div>
      </div>
      <SideModal title="Виджеты" elementId={addWidget} status={!!sideModal} handlerSideModalStatus={handlerSideModalStatus} width="66%" component="widgetList" />
    </div>
  );
};

export default EditorWindow;
