export const setWidget = (childrens, widget, parentid) => {
  return childrens.map((child) => {
    if (child.id == parentid) {
      child.childrens.push(widget);
      child.childrens.filter((el) => !el.length);
      return child;
    }
    return setWidget(child.childrens, widget, parentid);
  }).flat();
};

export const removeWidget = (template, widgetId) => {
  template.childrens = template.childrens.map((child) => {
    if (child.id == widgetId) {
      template.childrens.filter((el) => el.id != widgetId);
    }
    return child;
    // return removeWidget(child.childrens, widgetId);
  }).flat();
  return template;
};

export const updateWidget2 = (template, widget) => {
  if (template.id == widget.id) {
    template.variable = widget.variable;
    return template;
  }
  template.childrens = template.childrens.map((child) => {
    if (child.id == widget.id) {
      child.variable = widget.variable;
      return child;
    }
    return updateWidget2(child, widget);
  });
  return template;
};

export const addWidget = (widget, id, templateUpdate, parentTemplate) => {
  widget.id = uuid();
  if (parentTemplate.childrens.length == 0) {
    parentTemplate.childrens.push(widget);
  } else setWidget([parentTemplate], widget, id);
  widget.parentid = id;
  templateUpdate(parentTemplate);
};
