import axios from 'axios';
import {
  ADD_NEWS, FETCH_NEWS, SAVE_NEWS, DELETE_NEWS, FETCH_NEWS_PAGE, FETCH_MAIN_NEWS, UPDATE_STATE_NEWS, UPDATE_NEWS,
} from './types';
import newsState from '../../state/newsState';

export const addNews = (title, description, content, newsState, imageId) => async (dispatch) => {
  await axios({
    method: 'post',
    url: 'https://localhost:5001/News/PostNews',
    data: {
      title,
      description,
      content,
      state: newsState,
      backgroundImageId: imageId,
    },
  });
  dispatch({
    type: ADD_NEWS,
    payload: true,
  });
};

export const updateStateNews = (id, newsState) => async (dispatch) => {
  try {
    await axios({
      method: 'put',
      url: 'https://localhost:5001/News/UpdateNewsState',
      data: {
        newsId: id,
        state: newsState,
      },
    });
    dispatch({
      type: UPDATE_STATE_NEWS,
      payload: true,
    });
  } catch (err) {
    throw new Error(`Произошла ошибка при обновлении состояния новости ${err} -  ID новости ${id}`);
  }
};

export const updateNews = (id, title, description, content, newsState, imageId) => async (dispatch) => {
    await axios({
      method: 'put',
      url: 'https://localhost:5001/News/UpdateNews',
      data: {
        newsId: id,
        title,
        description,
        content,
        state: newsState,
        imageId,
      },
    });
    dispatch({
      type: UPDATE_NEWS,
      payload: true,
    });
};

export const deleteNews = (id) => async (dispatch) => {
  try {
    await axios({
      method: 'delete',
      url: 'https://localhost:5001/News/DeleteNews',
      data: {
        newsId: id,
      },
    });
    dispatch({
      type: DELETE_NEWS,
      payload: true,
    });
  } catch (err) {
    throw new Error(`Произошла ошибка при удалении новости ${err} -  ID новости ${id}`);
  }
};

export const fetchNews = (id) => (dispatch) => {
  try {
    const news = axios.get(`https://localhost:5001/News/GetNews?newsId=${id}`);
    dispatch({
      type: FETCH_NEWS,
      payload: news,
    });
    return news;
  } catch (err) {
    throw new Error(`Произошла ошибка при запросе новости ${err}`);
  }
};

export const fetchNewsByPage = (page, news = []) => async (dispatch) => {
  const newsDataPublished = await axios.get(`https://localhost:5001/News/GetNewsList?page=${page}&newsState=${newsState.published}`);
  const newsDataDraft = await axios.get(`https://localhost:5001/News/GetNewsList?page=${page}&newsState=${newsState.draft}`);
  const newsDataArhived = await axios.get(`https://localhost:5001/News/GetNewsList?page=${page}&newsState=${newsState.archived}`);
  const response = newsDataPublished.data;
  response.newsList.sort((a, b) => new Date(b.creationDate).getTime() - new Date(a.creationDate).getTime());
  const publishedNews = news.concat(response.newsList);
  dispatch({
    type: FETCH_NEWS_PAGE,
    payload: {
      news: {
        published: publishedNews,
        draft: newsDataDraft.data,
        archived: newsDataArhived.data,
        editing: newsDataPublished.data,
      },
      isLastPage: (publishedNews.length == response.newsCount),
    },
  });
};

export const fetchMainNews = (newsId) => async (dispatch) => {
  const response = await axios.get(`https://localhost:5001/News/GetNewsCard?newsId=${newsId}`);
  dispatch({
    type: FETCH_MAIN_NEWS,
    payload: response.data,
  });
};

export function saveNews(news) {
  return (dispatch) => {
    dispatch({
      type: FETCH_NEWS,
      payload: [1, 2, 3],
    });
  };
}
