import pageImage, { withRouter } from 'react-router';
import React, { useState, useEffect } from 'react';
import { BiPlus } from 'react-icons/bi';
import { connect } from 'react-redux';
import SideModal from './sideModal';
import * as actions from '../store/actions/templateAction';
import $api, { API_URL } from '../http';

const Pages = (props) => {
  const { dispatch } = props;
  const [sideModal, setSideModal] = useState(false);
  const [selectedPage, setSelectedPage] = useState(false);
  const [pages, setPages] = useState([]);
  const [sideEditModal, setSideEditModal] = useState(false);
  const handlerSideModalStatus = (status) => setSideModal(status);
  const handlerSideEditModalStatus = (status) => setSideEditModal(status);
  const sideEditModalHanler = (page) => {
    setSelectedPage(page);
    handlerSideEditModalStatus(true);
  };
  useEffect(async () => {
    dispatch(actions.getTemplates());
    await $api.get('templates/list')
    .then((res) => {
      setPages(res.data);
    });
  }, []);
  const templBackgraundImage = './img/page.png';
  return (
    <div className="pages-widget">
      <div className="pages-widget_header">
        <div className="pages-widget_header_title">Страницы</div>
        <div className="pages-widget_header_title_add-page" onClick={() => handlerSideModalStatus(true)}>
          <BiPlus />
          Добавить
        </div>
      </div>
      {pages.map((page, index) => (
        <div key={(`page_${page.id}`)} className="pages-widget_item" onClick={() => sideEditModalHanler(page)}>
          <div className="image-container">
            <div className="image-container_img" style={{ backgroundImage: `url('${templBackgraundImage}')` }} />
          </div>
          <div className="pages_preview">
            <div className="pages_preview_title">
              {page.title}
            </div>
            <div className="pages_preview_date">
              {page.creationDate}
            </div>
          </div>
        </div>
      ))}
      <SideModal title="Создание страницы" status={sideModal} handlerSideModalStatus={handlerSideModalStatus} width="50%" component="createPage" />
      <SideModal title="Редактирование страницы" elementId={selectedPage} status={sideEditModal} handlerSideModalStatus={handlerSideEditModalStatus} width="50%" component="editPage" />
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    templates: state.page.templates,
  };
};

export default withRouter(connect(mapStateToProps)(Pages));
