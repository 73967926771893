import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import {
 HashRouter as Router, Route, Switch, withRouter, Redirect,
} from 'react-router-dom';
import * as actions from './store/actions/loginAction';
import Popup from './components/popup';

// layouts
import MainLayout from './components/mainLayout';

// pages
import MainPage from './pages/mainPage';
import EditorPage from './pages/editorPage';
import PreViewPage from './pages/previewPage';
import NewsPage from './pages/newsPage';
import ProfilePage from './pages/profilePage';

import AttestationPage from './pages/attestationPage';
import LoginPage from './pages/loginPage';

const App = (props) => {
  const isAuth = useSelector((state) => {
    console.log(state);
    return state.user.isAuth;
  });

  const user = useSelector((state) => state.user.userData);
  useEffect(() => {
    if (isAuth) {
      // props.store.dispatch(actions.checkAuth());
    } else {
      props.history.push('/login');
    }
  }, [isAuth]);
  return (
    <>
      <Popup />
      <Switch>
        {isAuth && (
          <Route
            exact
            path="/"
            render={(props) => (
              <MainLayout {...props}>
                <MainPage {...props} />
              </MainLayout>
              )}
          />
        )}
        {isAuth && (
          <Route
            exact
            path="/profile"
            render={(props) => (
              <MainLayout {...props}>
                <ProfilePage {...props} />
              </MainLayout>
              )}
          />
        )}
        {isAuth && (
          <Route
            exact
            path="/files"
            render={(props) => (
              <MainLayout {...props}>
                <NewsPage {...props} />
              </MainLayout>
              )}
          />
        )}
        {isAuth && (
          <Route
            exact
            path="/editor"
            render={(props) => (
              <MainLayout {...props}>
                <EditorPage {...props} />
              </MainLayout>
              )}
          />
        )}
        {isAuth && (
          <Route
            exact
            path="/view"
            render={(props) => (
              <MainLayout {...props}>
                <PreViewPage {...props} />
              </MainLayout>
              )}
          />
        )}
        <Route
          path="/login"
          render={(props) => (isAuth ? <Redirect to={{ pathname: '/' }} /> : <LoginPage {...props} />)}
        />
      </Switch>
    </>
  );
};

export default withRouter(App);
