import React, { useState } from 'react';
import { BiPlus } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';
import SideModal from './sideModal';

const AttestationNavBar = (props) => {
    const { page } = props.match.params;
    const [sideModal, setSideModal] = useState(false);
    const handlerSideModalStatus = (status) => {
      setSideModal(status);
    };
    const navBarItems = [
      {
        id: 1,
        text: 'Мои цели',
        path: 'myGoals',
      },
      {
        id: 2,
        text: 'Справочник целей',
        path: 'directoryGoals',
      },
      {
        id: 3,
        text: 'Сотрудники',
        path: 'employes',
      },
      {
        id: 4,
        text: 'Статистика',
        path: 'statistics',
      },
      {
        id: 5,
        text: 'Архив целей',
        path: 'arhiveGoals',
      },
    ];
    return (
      <div className="attestation-nav-bar">
        <div className="attestation-nav-bar_container">
          <div className="attestation-nav-bar_items">
            {navBarItems.map((item) => {
                return (
                  <div
                    className="attestation-nav-bar_item"
                    key={item.id}
                  >
                    <NavLink to={`/attestation/${ item.path}`} alt={item.text} style={{ color: item.path === page ? '#2C3E50' : '' }}>
                      {item.text}
                    </NavLink>
                    <div
                      className="attestation-nav-bar_item__active"
                      style={{ opacity: item.path === page ? '1' : '' }}
                    />
                  </div>
                );
            })}
          </div>
          <div className="attestation-nav-bar_item_add-goals" onClick={() => handlerSideModalStatus(true)}>
            <BiPlus />
            цель
          </div>
        </div>
        <SideModal title="Создание цели" status={sideModal} handlerSideModalStatus={handlerSideModalStatus} width="50%" component="createGoals" />
      </div>
    );
};

export default withRouter(AttestationNavBar);
