const cards = [{
  id: 1,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 1',
  title: 'title1',
  description: 'description',
  creationDate: '06.08.1998',
}, {
  id: 2,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 2',
  title: 'title2',
  description: 'description',
  creationDate: '06.08.1998',
}, {
  id: 3,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 3',
  title: 'title3',
  description: 'description',
  creationDate: '06.08.1998',
}, {
  id: 4,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 4',
  title: 'title4',
  description: 'description',
  creationDate: '06.08.1998',
}, {
  id: 5,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 5',
  title: 'title5',
  description: 'description',
  creationDate: '06.16.1998',
}, {
  id: 6,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 6',
  title: 'title6',
  description: 'description',
  creationDate: '06.16.1998',
}, {
  id: 7,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 7',
  title: 'title7',
  description: 'description',
  creationDate: '06.16.1998',
}, {
  id: 8,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 1',
  title: 'title8',
  description: 'description',
  creationDate: '06.16.1998',
}, {
  id: 9,
  backgroundImageUrl: './img/list.png',
  authorDepartmentName: 'Подразделение 9',
  title: 'title9',
  description: 'description',
  creationDate: '06.16.1998',
}];

export default cards;
