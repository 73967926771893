import React from 'react';
import moment from 'moment';
import goalsState from '../state/goalsState';
import { MdEventNote } from 'react-icons/md';

const MyGoals = ({ goals }) => {
    console.log(goals);
    return (
      <div className="my-goals">
        <div className="my-goals_container">
          <div className="my-goals_container_items">
            {goals.map((item) => {
              return (
                <div className="my-goals_container_item" key={item.id}>
                  <div className="my-goals_container_item_header">
                    {Object.values(goalsState).filter((element) => element.status === item.status).map((el, index) => {
                      return (
                        <div className="my-goals_container_item_header_status" style={{ background: el.background, color: el.color }} key={index}>
                          {el.text}
                        </div>
                      );
                    })}
                  </div>
                  <div className="my-goals_container_item_goals_content">
                    <div className="my-goals_container_item_goals_content_title">{item.name}</div>
                    <div className="my-goals_container_item_goals_content_achievement">{item.achievement}</div>
                  </div>
                  <div className="my-goals_container_item_goals_date">
                    <MdEventNote />
                    {`${moment(item.startDate).format('DD.MM.YYYY')} - ${moment(item.endDate).format('DD.MM.YYYY')}`}
                  </div>
                  <div className="my-goals_container_item_goals_progress_bar" />
                </div>
              );
            })}
          </div>
        </div>
      </div>
    );
};
export default MyGoals;
