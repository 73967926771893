import React from 'react';
import { useSelector } from 'react-redux';
import { IoMdHeartEmpty } from 'react-icons/io';
import { FiMessageSquare } from 'react-icons/fi';

const MainNews = (props) => {
  let card = useSelector((state) => state.news.newsCard);
  card = card.length !== 0 ? card : props.news;
  return (
    <div className="main-news show-news">
      <div className="main-news_container">
        <div className="main-news_container_author">
          <div className="main-news_container_author_image" />
          <div className="main-news_container_author_info">
            <span>{card.authorName}</span>
            <span>{card.authorDepartmentName}</span>
          </div>
        </div>
        <div className="main-news_container_image" style={{ backgroundImage: `url('https://localhost:5001/api/service/graph/${card.backgroundImageUrl}')` }} />
        <div className="main-news_container_news_info">
          <div className="main-news_container_news_info_title">
            {card.title}
          </div>
          <div className="main-news_container_news_info_description">
            {card.description}
          </div>
        </div>
        <div className="main-news_container_news_comments_likes">
          <div className="main-news_container_news_likes news_icons">
            <IoMdHeartEmpty className="news_item_icon" />
            <div className="main-news_container_news_likes_count">
              {card.likesCount === 0 ? '' : card.likesCount}
            </div>
          </div>
          <div className="main-news_container_news_comments news_icons">
            <FiMessageSquare className="news_item_icon" />
            <div className="main-news_container_news_comments_count">
              {card.commentsCount === 0 ? '' : card.commentsCount }
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MainNews;
