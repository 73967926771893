/* eslint-disable import/prefer-default-export */
import { ADD_POPUP, DELETE_POPUPS } from './types';

export const addPopup = (type, value) => (dispatch) => {
    dispatch({
      type: ADD_POPUP,
      payload: {
        type,
        value,
      },
    });
  };

  export const deletePopups = () => (dispatch) => {
    dispatch({
      type: DELETE_POPUPS,
      payload: [],
    });
  };
