import {
  ADD_NEWS, FETCH_NEWS, FETCH_NEWS_PAGE, FETCH_MAIN_NEWS, UPDATE_STATE_NEWS, DELETE_NEWS, UPDATE_NEWS,
} from '../actions/types';

const INITIAL_STATE = {
  arr: {},
  createNewsStatus: false,
  updateNewsState: false,
  updateNews: false,
  deleteNews: false,
  latestNews: {},
  isLastPage: false,
  newsCard: [],
  currentNews: [],
};

export default (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_NEWS:
      return { ...state, createNewsStatus: action.payload };
    case UPDATE_STATE_NEWS:
      return { ...state, updateNewsState: action.payload };
    case UPDATE_NEWS:
      return { ...state, updateNews: action.payload };
    case DELETE_NEWS:
      return { ...state, deleteNews: action.payload };
    case FETCH_NEWS:
      return { ...state, currentNews: action.payload };
    case FETCH_NEWS_PAGE:
      return { ...state, arr: action.payload.news, isLastPage: action.payload.isLastPage };
    case FETCH_MAIN_NEWS:
      return { ...state, newsCard: action.payload };
    default:
      return state;
  }
};
