import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { connect, useSelector } from 'react-redux';
import { withRouter } from 'react-router';
import * as actions from '../store/actions/loginAction';
import { IMAGE_URL } from '../http';

const Header = (props) => {
  const [userInfo, setUserInfo] = useState(false);
  const logout = () => {
    props.dispatch(actions.logout());
  };
  const toggleUserInfo = () => setUserInfo(!userInfo);
  const user = useSelector((state) => state.user.userData);
  return (
    <div className="header">
      <div className="left-side">
        <div className="burger burger_active">
          <div className="burger__middle-line" />
        </div>
        <div className="navigation">
          <NavLink to="/files" alt="Файлы">
            <div className={`navigation__item ${props.location.pathname == '/files' ? 'navigation__item_type_avtive' : ''}`}>Файлы</div>
          </NavLink>
          <NavLink to="/" alt="Страницы">
            <div className={`navigation__item ${props.location.pathname == '/' ? 'navigation__item_type_avtive' : ''}`}>Страницы</div>
          </NavLink>
          <NavLink to="/editor" alt="Редактор">
            <div className={`navigation__item ${props.location.pathname == '/editor' ? 'navigation__item_type_avtive' : ''}`}>Редактор</div>
          </NavLink>
        </div>
      </div>
      <div className="right-side">
        <div className="search">
          <i className="fa fa-search" />
        </div>
        <div className="user-info" onClick={toggleUserInfo}>
          <div className="user-info__image" style={{ backgroundImage: `url(${IMAGE_URL}${user.avatarLink})` }} />
          <div className="user-info__content-group">
            <div className="user-info__content-group__name">
              {user.fullName}
            </div>
            <div className="user-info__content-group__position">
              { (user.departmentPositions ?? 'Разработчик') }
            </div>
          </div>
          { userInfo && (
          <div className="user-info_dropdown-panel">
            <NavLink to="/profile" alt="Личный кабинет">
              <div className="user-info_dropdown-panel_item">Личный кабинет</div>
            </NavLink>
            <div className="user-info_dropdown-panel_item" onClick={logout}>Выход</div>
          </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default withRouter(connect(null)(Header));
