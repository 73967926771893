import React from 'react';

import Pages from '../components/pages';

const Main = () => (
  <div className="container">
    <div className="columns">
      <div className="column first">
        <Pages />
      </div>
    </div>
  </div>
);

export default Main;
