import React, { useEffect } from 'react';
import { withRouter } from 'react-router';
import { connect, useDispatch } from 'react-redux';
import { HashRouter as Router, Route, Switch } from 'react-router-dom';
import AttestationNavBar from '../components/attestationNavBar';
import Loader from '../components/loader';
import MyGoals from '../components/myGoals';
import DirectoryGoals from '../components/directoryGoals';
import { getGoals } from '../store/actions/attestationAction';

const AttestationPage = (props) => {
    const dispatch = useDispatch();
    const { history } = props;
    useEffect(() => {
      dispatch(getGoals());
    }, []);
    return (
      <div className="attestation-wrapper">
        <AttestationNavBar />
        <div className="container container-attestation">
          <div className="row">
            <Router>
              <Switch>
                <Route exact path="/attestation/myGoals">
                  {props.goals.length !== 0 ? <MyGoals goals={props.goals} /> : <Loader /> }
                </Route>
                <Route exact path="/attestation/directoryGoals">
                  {props.goals.length !== 0 ? <DirectoryGoals goals={props.goals} /> : <Loader /> }
                </Route>
              </Switch>
            </Router>
          </div>
        </div>
      </div>
    );
};

const mapStateToProps = (state) => ({
  goals: state.attestation.goals,
});

export default withRouter(connect(mapStateToProps)(AttestationPage));
