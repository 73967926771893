import { UPLOAD_IMAGE } from '../actions/types';

    const INITIAL_STATE = {
       image: {},
     };

     export default (state = INITIAL_STATE, action) => {
       switch (action.type) {
         case UPLOAD_IMAGE:
           return { ...state, image: action.payload };
         default:
           return state;
       }
     };
