import { LOG_OUT, LOG_IN, REFRESH } from './types';
import { addPopup } from './popupAction';
import popupState from '../../state/popupState';
import $api, { API_URL } from '../../http';
import axios from 'axios';

export const logout = () => (dispatch) => {
  localStorage.removeItem('token');
  localStorage.removeItem('email');
  dispatch({
    type: LOG_OUT,
    payload: {
      user: null,
      isAuth: false,
      token: '',
    },
  });
};

export const login = (email, password) => async (dispatch) => {
  const response = await $api.post(`/Auth/Login?UserName=${email}&Password=${password}`);
  if (!response.data.response.successful) {
    dispatch(addPopup(popupState.error, 'Пользователь не найден.'));
    return;
  }
  localStorage.setItem('email', email);
  localStorage.setItem('token', response.data.response.token);
  localStorage.setItem('refreshToken', response.data.response.refreshToken);
  dispatch({
    type: LOG_IN,
    payload: {
      isAuth: true,
      user: response.data.user,
      token: response.data.response.token,
    },
  });
};

export const checkAuth = () => async (dispatch) => {
  console.log(checkAuth);
  try {
    const response = await $api.post(`${API_URL}Auth/Refresh?Token=${localStorage.getItem('token')}&RefreshToken=${localStorage.getItem('refreshToken')}`);
    if (!response.data.successful) {
      dispatch(addPopup(popupState.error, 'Токен не действителен.'));
      localStorage.removeItem('refreshToken');
      localStorage.removeItem('token');
      dispatch({
        type: LOG_OUT,
        payload: {
          user: null,
          isAuth: false,
          token: '',
        },
      });
      return;
    }

    localStorage.setItem('token', response.data.token);
    localStorage.setItem('refreshToken', response.data.refreshToken);
    dispatch({
      type: REFRESH,
      payload: {
        isAuth: true,
        token: response.data.token,
      },
    });
  } catch (e) {
    console.log(e);
    localStorage.removeItem('token');
    localStorage.removeItem('email');
    dispatch({
      type: LOG_OUT,
      payload: {
        user: null,
        isAuth: false,
        token: '',
      },
    });
  }
};
