import React, { useState, useEffect } from 'react';
import { FiAlertCircle, FiBell } from 'react-icons/fi';
import { TiWarningOutline } from 'react-icons/ti';
import { BiCheckCircle } from 'react-icons/bi';
import { connect, useDispatch } from 'react-redux';
import { deletePopups } from '../store/actions/popupAction';
import popupState from '../state/popupState';

const Popup = ({ syncPopups }) => {
    const dispatch = useDispatch();
    const [timerId, setTimerId] = useState(null);
    useEffect(() => {
      clearTimeout(timerId);
      setTimerId(setTimeout(() => deleteElementFromArr(), 3000));
   }, [syncPopups]);
    const deletePopupHandlerClick = (event) => {
        event.target.classList.add('hide_popup');
    };
    const deleteElementFromArr = () => {
    const popups = [...document.querySelectorAll('.popup-container_item')];
    popups.forEach((item, index) => {
      setTimeout(() => {
        item.classList.add('hide_popup');
        if (popups.every((item) => item.classList.contains('hide_popup'))) {
          dispatch(deletePopups());
        }
      }, index * 1000);
    });
  };

    return (
      <div className="popup-container">
        <div className="popup-container_items">
          {syncPopups.map((pop, index) => {
                return (
                  <div
                    className={`popup-container_item ${pop.type} show_popup`}
                    onClick={(event) => deletePopupHandlerClick(event, pop.value)}
                    key={index}
                  >
                    {pop.type === popupState.warning && <TiWarningOutline />}
                    {pop.type === popupState.error && <FiAlertCircle />}
                    {pop.type === popupState.notification && <FiBell />}
                    {pop.type === popupState.success && <BiCheckCircle />}
                    {pop.value}
                  </div>
                );
            })}
        </div>
      </div>
    );
};

const mapStateToProps = (state) => {
    return {
        syncPopups: state.popup.popups,
    };
};

export default connect(mapStateToProps, null)(Popup);
