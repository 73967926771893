import React, { useState, Suspense } from 'react';
import { connect } from 'react-redux';
import pageImage, { withRouter } from 'react-router';
import { BiPlus } from 'react-icons/bi';
import { AiOutlineEye } from 'react-icons/ai';
import SideModal from './sideModal';
import uuid from 'react-uuid';

const PreviewWindow = ({ template }) => {
  const [viewMode, setViewMode] = useState(false);
  const [sideModal, setSideModal] = useState(false);
  const loadComponent = (component) => React.lazy(() => import(`./${component}.js`));
  const ChildrenDrower = template && loadComponent(template.mainTemplate);
  const [selectedPage, setSelectedPage] = useState(false);
  const handlerSideModalStatus = (status) => {
    setSideModal(status);
  };

  return (
    <div className="editor-window view-mode">
      <div className="editor-window_container">
        {template && (
          <Suspense fallback={<div>Loading...</div>}>
            <ChildrenDrower template={template} />
          </Suspense>
        )}
      </div>
    </div>
  );
};

export default PreviewWindow;
