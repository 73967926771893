import React, { useState } from 'react';
import SideModal from './sideModal';
import { BiPlus } from 'react-icons/bi';
import { NavLink } from 'react-router-dom';
import { withRouter } from 'react-router';

 const NewsNavBar = (props) => {
    const { page } = props.match.params;
    const [sideModal, setSideModal] = useState(false);
    const handlerSideModalStatus = (status) => {
      setSideModal(status);
    };
    const navBarItems = [
      {
          id: 1,
          text: 'Новости',
          path: 'newsList',
      },
      {
          id: 2,
          text: 'Черновики',
          path: 'draft',
      },
      {
          id: 3,
          text: 'Редактирование',
          path: 'editing',
      },
      {
          id: 4,
          text: 'Архив',
          path: 'archive',
      },
    ];
    return (
      <div className="news-nav-bar">
        <div className="news-nav-bar_container">
          <div className="news-nav-bar_items">
            {navBarItems.map((item) => {
                return (
                  <div
                    className="news-nav-bar_item"
                    key={item.id}
                  >
                    <NavLink to={`/news/${ item.path}`} alt={item.text} style={{ color: item.path === page ? '#2C3E50' : '' }}>
                      {item.text}
                    </NavLink>
                    <div
                      className="news-nav-bar_item__active"
                      style={{ opacity: item.path === page ? '1' : '' }}
                    />
                  </div>
                );
            })}
          </div>
          <div className="news-nav-bar_item_add-news" onClick={() => handlerSideModalStatus(true)}>
            <BiPlus />
            новость
          </div>
        </div>
        <SideModal title="Создание новости" status={sideModal} handlerSideModalStatus={handlerSideModalStatus} width="70%" component="createNews" />
      </div>
    );
  };

export default withRouter(NewsNavBar);
