import React, { Suspense } from 'react';
import { AiOutlineArrowLeft } from 'react-icons/ai';
import PropTypes from 'prop-types';

const SideModal = ({
 title, width, component, status, handlerSideModalStatus, elementId, sideData, data,
 }) => {
  const loadComponent = () => React.lazy(() => import(`./${component}.js`));
  const Component = loadComponent(component);
  const handlerModal = (event) => {
    if (event.target.classList.contains('modal') || event.target.classList.contains('modal_content-side-modal_back')) {
      handlerSideModalStatus(false);
    }
  };
  return (
    <div className={status ? 'modal modal_active' : 'modal modal_hide'} onClick={(event) => handlerModal(event)}>
      <div className="modal_content-side-modal" style={{ width: status ? width : '0px', opacity: status ? '1' : '0' }}>
        <div className="modal_content-side-modal_head">
          <div className="modal_content-side-modal_back" onClick={(event) => handlerModal(event)}>
            <AiOutlineArrowLeft />
            Назад
          </div>
          {title && <div className="modal_content-side-modal_title">{title}</div>}
        </div>
        <div className="modal_content-side-modal_container">
          <Suspense fallback={<div>Loading...</div>}>
            <Component handlerSideModalStatus={handlerSideModalStatus} elementId={elementId} sideData={sideData} status={status} />
          </Suspense>
        </div>
      </div>
    </div>
  );
};

SideModal.propTypes = {
  component: PropTypes.string.isRequired,
  handlerSideModalStatus: PropTypes.func.isRequired,
  status: PropTypes.bool.isRequired,
};

export default SideModal;
