import React from 'react';
import moment from 'moment';
import { IMAGE_URL } from '../http';
import { connect, useSelector } from 'react-redux';

const UserCard = () => {
  const user = useSelector((state) => state.user.userData);
  const getWorkTime = () => {
    const years = moment(new Date()).diff(moment(new Date(user.dateBegin)), 'years').toString();
    const months = moment(new Date()).diff(moment(new Date(user.dateBegin)), 'months') - (years * 12);
    return `${years} г. ${months} мес.`;
  };
  console.log(user);
  return (
    <div className="user-card-widget">
      <div className="user-card-wiget_content">
        <div className="user-card-wiget_content_avatar" style={{ backgroundImage: `url(${IMAGE_URL}${user.avatarLink})` }} />
        <div className="user-card-wiget_content_info">
          <div className="user-card-wiget_content_info_fullname">
            {user.fullName}
          </div>
          <div className="user-card-wiget_content_info_position">
            { (user.departmentPositions[0] || { userPositionName: null }).userPositionName }
          </div>
          <div className="user-card-wiget_content_info_workstart-date">
            {`В компании ${getWorkTime()}
             (c ${moment(new Date(user.dateBegin)).format('DD.MM.YYYY')})`}
          </div>
        </div>
      </div>
    </div>
);
};

export default UserCard;
