import React, { useState } from 'react';
import { useSelector } from 'react-redux';

import { IoIosArrowDown } from 'react-icons/io';

const Selector = (props) => {
  const options = [
    {
      text: 'Все подразделения',
      value: 'all',
    },
    {
      text: 'Подразделение 1',
      value: 0,
    },
    {
      text: 'Подразделение 2',
      value: 1,
    },
    {
      text: 'Подразделение 3',
      value: 2,
    },
  ];
  const [showOptions, setShowOptions] = useState(false);
  const [selecedOption, setSelectedOption] = useState(options[0]);
  return (
    <div className="selector" onClick={() => setShowOptions(!showOptions)}>
      <div className="selector_selected-item">{selecedOption.text}</div>
      <IoIosArrowDown />
      {showOptions && (
      <div className="selector_options">
        {options.map((option, optionIndex) => (
          <div className="selector_options_item" key={optionIndex} onClick={() => setSelectedOption(option)}>{option.text}</div>
        ))}
      </div>
      )}
    </div>
  );
};

export default Selector;
